import React from 'react'
import tw, { styled } from 'twin.macro'

import { MainLayout } from '@common/layouts'
import { SEO } from '@components'

const Content = styled.div`
  ${tw`prose prose mx-auto max-w-screen-xl text-justify leading-6 antialiased py-16 px-6 text-gray-800`}
`

const MedicalTermsOfServicePage = ({ siteUrl }) => {
  return (
    <MainLayout>
      <Content>
        <SEO title="Terms of Use" />

        <h1>HelloMD Terms of Use</h1>
        <div class="mb-4">Last modified: July 1, 2021</div>
        <p>
          BY “REGISTERING AN ACCOUNT” WITH HELLOMD,” YOU ACKNOWLEDGE THAT YOU HAVE
          READ, ACCEPTED, AND AGREED TO BE BOUND BY THESE TERMS OF USE. IF YOU DO NOT
          AGREE TO THESE TERMS OF USE, DO NOT “REGISTER AN ACCOUNT”, YOU WILL NOT BE
          ABLE TO USE OR RECEIVE THE BENEFIT OF THE HELLOMD SERVICES. YOU HEREBY GRANT
          AGENCY AUTHORITY TO ANY PARTY WHO “REGISTERS AN ACCOUNT” ON YOUR BEHALF.
        </p>
        <p>
          This website, located at https://www.hellomd.ca, and the HelloMD Mobile
          Applications (collectively, the “Site”), are owned and operated by HelloMD
          Corporation (d/b/a HelloMD) (and collectively with its subsidiaries,
          affiliates, successors, and assigns, “HelloMD”, “we,” or “us”).
        </p>
        <p>
          subsidiaries, affiliates, successors, and assigns, “HelloMD”, “we,” or
          “us”). This Site provides Qualifying Patients with (i) information on health
          care practitioners and the services and facilities provided by the same who
          are subscribers to the HelloMD services (collectively hereafter “Licensed
          Health Care Provider(s)”), (ii) concierge-type assistance in planning for
          health care procedures and appointments with Licensed Health Care Providers,
          especially as it relates to location and geographic-specific information in
          the area surrounding the Licensed Health Care Provider or facility, (iii)
          development and gathering of health care records and health care information
          with retention of the same for use in Licensed Health Care Provider
          appointments and communications, (iv) administrative support in connection
          with third-party financing and payment options for Licensed Health Care
          Provider services and submission of third-party insurance claims for
          reimbursement of health care expenses when possible, and (v) in certain
          circumstances, telecommunications support using the Site for direct access
          to Licensed Health Care Providers and facilities with whom a qualifying
          patient has an existing Licensed Health Care Provider to patient
          relationship for communication, consultations, assessments, and ongoing
          treatment.
        </p>
        <p>
          This Site also makes available communication forums for the exchange of
          general wellness information and otherwise between Qualifying Patients,
          Licensed Health Care Providers, and others. All such communication is at the
          sole discretion of participants in the forums and HelloMD does not review or
          confirm any information or content for accuracy or any other purpose. Except
          for the limited subscription arrangement with Licensed Health Care Providers
          who subscribe to the HelloMD services, HelloMD has no relationship or
          affiliation with any individual providing content on the Site as part of
          these communication forums. or affiliation with any individual providing
          content on the Site as part of these communication forums.
        </p>
        <p>
          IF YOU HAVE A MEDICAL EMERGENCY, IMMEDIATELY CALL YOUR DOCTOR OR DIAL 911.
        </p>
        <p>
          Your access to and use of the Site are subject to these terms of use (“Terms
          of Use”), as well as all applicable laws and regulations. Please read these
          Terms of Use carefully. If you do not accept and agree to be bound by any of
          these Terms of Use, you are not authorized to access or otherwise use the
          Site or any information or Content contained on the Site, as defined in
          these Terms of Use. Your access to and use of the Site constitutes your
          acceptance of and agreement to abide by each of these terms and conditions
          set forth below. This Site is continually under development, and HelloMD
          reserves the right to revise or remove any part of these Terms of Use in its
          sole discretion at any time and without prior notice to you. Any changes to
          these Terms of Use are effective upon posting to the Site. Unless otherwise
          indicated, any new Content added to the Site is also subject to these Terms
          of Use upon posting to the Site. If you disagree with these Terms of Use,
          your sole remedy is to discontinue your use of the Site. Your continued use
          after a change has been posted constitutes your acceptance of the changes.
          THIS WEBSITE FACILITATES COMMUNICATION AND COORDINATION WITH YOUR LICENSED
          HEALTH CARE PROVIDER, AND IN CERTAIN CIRCUMSTANCES FACILITATES DIRECT
          COMMUNICATION WITH THE SAME. IT DOES NOT REPLACE YOUR RELATIONSHIP WITH ANY
          LICENSED HEALTH CARE PROVIDER OR OTHER HEALTH CARE SERVICE, AND HELLOMD DOES
          NOT PROVIDE MEDICAL ADVICE, RECOMMENDATIONS OF CANNABIS FOR MEDICAL USE,
          DISTRIBUTION OF CANNABIS IN ANY FORM WHATSOEVER FOR THE PURPOSE OF MEDICAL
          TREATMENT OR OTHERWISE, CARE, DIAGNOSIS, OR TREATMENT.
        </p>
        <h2>Acceptance of Use and Terms of Use</h2>
        <p>
          HelloMD does not in any way engage in the cultivation, manufacture,
          distribution, sale, or online sale of cannabis or any cannabis product for
          medical use or otherwise. Licensed Health Care Providers subscribing to the
          Site do not in any way engage in the cultivation, manufacture, distribution,
          sale, or online sale of cannabis or any cannabis product for medical use or
          otherwise. In order to gain access to and to use the Site, you must
          demonstrate to the satisfaction of HelloMD that you currently reside in a
          Canadian province or territory in which HelloMD then makes its services
          available Licensed Health Care Providers are in no way obligated to
          recommend to you the use of cannabis for your medical treatment. It is in
          the sole discretion of your Licensed Health Care Provider, through its bona
          fide Health Care Provider to patient relationship with you and corresponding
          medical examination or series of examinations to be conducted in accordance
          with the applicable laws and regulations, to determine whether cannabis
          would be effective as a treatment for your condition(s) (“Patient
          Recommendation”). Neither HelloMD nor its Licensed Health Care Providers
          make any warranties or guaranties that the use of medical cannabis would be
          an effective treatment for your medical conditions.
        </p>
        <p>
          This Site is intended for use only by persons who have reached the age of
          majority in their place of residence; if you area minor, your parent or
          legal guardian must accompany and strictly monitor your use of the Site.
        </p>
        <h2>Corporate Structure</h2>
        <p>
          HelloMD is a corporation that makes the Site, related technology, and other
          administrative and management services available for Licensed Health Care
          Providers and their Qualifying Patients to coordinate and communicate as
          described in these Terms of Use and otherwise on the Site.
        </p>
        <h2>Your Relationship with HelloMD</h2>
        <p>
          HelloMD provides a platform for you to consult with your Licensed Health
          Care Provider and to obtain access to additional information regarding the
          use of cannabis for medical treatment, which you may or may not choose to
          utilize in planning your health care and wellness. The services provided
          through the Site are not a substitute for direct in-person health care
          services. The decision to focus on diagnosis, treatment recommendations, or
          both rests with you and your Licensed Health Care Provider, whom you select
          in your sole discretion. You understand that by coordinating and in certain
          cases consulting with a Licensed Health Care Provider through the Site, you
          are not entering into a doctor-patient relationship with HelloMD and you
          understand and acknowledge that HelloMD is neither a Licensed Health Care
          Provider nor a provider of cannabis for medical use.
        </p>
        <p>
          By accepting the Terms of Use, you understand that your Licensed Health Care
          Provider may send you messages, reports, and emails via the Site regarding
          the treatment of your condition. It is your responsibility to monitor these
          messages, reports, and emails. You agree that you will not hold HelloMD
          liable for any loss, injury, or claims of any kind resulting from your
          failure to read these messages or for your failure to comply with any
          treatment recommendations contained in these messages.
        </p>
        <h2>Duty to Provide Information and Access</h2>
        <p>
          If you consult with a Licensed Health Care Provider through the Site, you
          have a duty to provide appropriate real-time audio and video access
          (consistent with any technical and quality requirements described on the
          Site) to enable an appropriate evaluation and connection by your Licensed
          Health Care Provider. You also have a duty to provide truthful and accurate
          responses on any patient forms or information requests your Licensed Health
          Care Provider may provide through the Site.
        </p>
        <h2>Privacy Policy</h2>
        <p>
          HelloMD understands the importance of confidentiality and privacy regarding
          your health information. Please see our Privacy Policy at
          https://www.hellomd.com/about-us#privacy for a description of how we may
          collect and use your personal information.
        </p>
        <h2>Independence of Licensed Health Care Providers</h2>
        <p>
          The professionals utilizing or featured on the Site are subscribers to and
          licensees of the Site and not employees or independent contractor service
          providers of HelloMD. Any opinions, advice, or information expressed by a
          Licensed Health Care Provider, health care facility, professional or
          specialist using or featured on the Site are of the facility and
          professional alone. They do not reflect the opinions of HelloMD. HelloMD
          does not recommend or endorse any specific tests, providers, products, the
          use of cannabis for medical efficacy, Patient Recommendations, procedures,
          medications, devices, opinions, or other information that may be mentioned
          on the Site or by a licensee of HelloMD. The inclusion of Licensed Health
          Care Providers on the Site or in any professional directory located on the
          Site does not imply recommendation or endorsement of such Licensed Health
          Care Provider nor is such information intended as a tool for verifying the
          credentials, qualifications, or abilities of any Licensed Health Care
          Provider contained therein.
        </p>
        <p>
          SUCH INFORMATION IS PROVIDED ON AN “AS-IS” BASIS AND HELLOMD DISCLAIMS ALL
          WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
          IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR PARTICULAR PURPOSE.
          HELLOMD SHALL IN NO EVENT BE LIABLE TO YOU OR TO ANYONE FOR ANY DECISION
          MADE OR ACTION TAKEN BY ANY PARTY (INCLUDING, WITHOUT LIMITATION, ANY SITE
          USER) IN RELIANCE ON INFORMATION ABOUT LICENSED HEALTH CARE PROVIDERS ON THE
          SITE. THE USE OF THE SITE BY ANY ENTITY OR INDIVIDUAL TO VERIFY THE
          CREDENTIALS OF LICENSED HEALTH CARE PROVIDERS IS PROHIBITED.
        </p>
        <h2>Registration; User Accounts, Passwords, and Security</h2>
        <p>
          You are not obligated to register with HelloMD in order to access the Site.
          However, certain services of the Site are available only to users who have
          registered with HelloMD or other persons affiliated with HelloMD who have
          been granted accounts with usernames and passwords (“Secure Users”). If you
          are a Secure User, you agree to accurately maintain and update any
          information about yourself that you have provided to HelloMD. If you do not
          maintain such information, or HelloMD has reasonable grounds to suspect as
          much, HelloMD has the right to suspend or terminate your account and your
          use of the Site. You may have as many active Secure User accounts on the
          Site as you choose at any given time, and only you may use your designated
          accounts to access the Site, and you may not use anyone else’s account at
          any time. With the exception of parents and legal guardians acting on behalf
          of minor Qualifying Patients in accordance with applicable laws and
          regulations, you may not make any request for a Patient Recommendation on
          behalf of any other individual with a HelloMD account or otherwise at any
          time. You also agree to promptly notify HelloMD of any unauthorized use of
          your username, password or any other breach of security that you become
          aware of involving or relating to the Site by emailing HelloMD at
          support@hellomd.com. In addition, you agree to exit from your Secure User
          account at the end of each session. HelloMD explicitly disclaims liability
          for any and all losses and damages arising from your failure to comply with
          this section. Our Site requires users to give us unique identifiers in order
          to log into many areas of the Site. We utilize these unique identifiers to
          verify the user’s identity and eligibility, in order to protect our members
          from the release of sensitive or personally identifiable information to
          unauthorized users. To help protect the privacy of data you transmit through
          the Site, where personally identifiable information is requested, we also
          use technology designed to encrypt the information that you input before it
          is sent to us using Secure Sockets Layer (SSL) technology or similar
          encryption technology.4 In addition, HelloMD takes steps to protect the user
          data we collect against unauthorized access. However, you should keep in
          mind that the Site and our services are run on software, hardware, and
          networks, any component of which may, from time to time, require maintenance
          or experience problems or breaches of security beyond our control. You must
          exercise caution, good sense, and sound judgment in using the Site. You are
          prohibited from violating, or attempting to violate, the security of the
          Site. Any such violations may result in criminal and/or civil penalties
          against you. HelloMD may investigate any alleged or suspected violations and
          if a criminal violation is suspected whether in regards to the Site or your
          procurement of a Patient Recommendation, HelloMD will cooperate with law
          enforcement agencies in their investigations.
        </p>
        <h2>Help Forums</h2>
        <p>
          This Site does now, and may in the future, offer a forum in which a user may
          request technical help regarding usage of the Site. You must exercise
          caution, good sense, and sound judgment in submitting messages to be posted
          to a forum. You are responsible for any material you submit in a post. You
          agree, represent, and warrant that any information you post is: truthful,
          accurate, not misleading, and offered in good faith; not invasive of
          another’s privacy, abusive, threatening or obscene; not infringing upon the
          copyright, trademark, or other intellectual property rights of others; not
          advertising your personal sale, cultivation, manufacture, or distribution of
          cannabis for medical or commercial use by yourself or by or for other
          individuals. You further agree, represent, and warrant that you have the
          right to post or transmit such information. By submitting a message to be
          posted to a forum, except as expressly provided in HelloMD’s privacy policy,
          you automatically grant HelloMD a royalty-free, perpetual, irrevocable,
          nonexclusive licence to use, reproduce, modify, publish, edit, translate,
          distribute, perform, and display the message, or any part of it (and
          derivative works thereof), alone or as part of other works in any form,
          media, or technology whether now known or hereafter developed, and to
          sublicense such rights through multiple tiers of sublicensees. You further
          agree that HelloMD has the right to use, without any payment or accounting
          to you or others, any concepts, know-how or ideas that you post to a forum.
          The content of such information, whether publicly posted or privately
          transmitted, is your sole responsibility. You must not post any material to
          a forum that you consider to be confidential or proprietary. Any material
          that you post will be considered non-confidential and nonproprietary. This
          policy serves to avoid potential misunderstandings or disputes regarding
          ownership of ideas.
        </p>
        <p>
          Under applicable copyright law, the author of any post is the owner of the
          copyright in the post. Aside from the licence that all registered users have
          granted to HelloMD upon submitting a message as stated above, no one
          visiting the forum is licensed to copy, reproduce, republish, modify,
          transmit, or distribute in any way any of the messages, or any portion of
          the messages, posted to the forum without the prior written permission of
          the copyright owner.
        </p>
        <p>
          HelloMD does not endorse, support, represent, or guarantee the truthfulness,
          accuracy, or reliability of any messages posted to a forum, or endorse any
          opinions expressed in such posts. You acknowledge that any reliance on
          material in posted messages will be at your own risk. Most of the content
          posted to the forums is provided by third parties like you. HelloMD is not
          responsible for this third-party content.
        </p>
        <h2>Credit Transactions</h2>
        <p>
          As part of the services on the Site, HelloMD facilitates your payment of
          certain fees to Licensed Health Care Providers and, with the exception of
          cannabis in any form whatsoever, may also offer HelloMD products and
          services for your purchase from time to time through the Site, however, the
          only accepted form of payment on the Site is by certain major credit cards,
          or ACH transfers; cash will not be accepted. When you pay by credit card,
          you hereby authorize HelloMD to bill and charge the credit card indicated in
          your order for any fees and any other amounts provided for in your order as
          the same become due and payable. In the event that your credit card expires
          or HelloMD is otherwise unable to debit the applicable amounts from your
          credit card, HelloMD shall provide notice to you, whereupon you shall
          immediately furnish HelloMD with a valid credit card for payment. Your
          request for services through the Site will not be fulfilled until full
          payment has been received and/or verified.
        </p>
        <h2>Limited License and Site Access; All Rights Reserved</h2>
        <p>
          The Site contains text, pictures, graphics, logos, button items, images,
          works of authorship, and other content (collectively, “Content”). We grant
          you a limited licence to access and make personal use of the Site, but not
          to download (other than for page caching) or modify it, or any portion of
          it, except with our express written consent. This license does not include:
          any resale or commercial use of the Site or the Content; any derivative use
          of the Site or the Content; or any use of data mining, robots, or similar
          data gathering and extraction tools.
        </p>
        <p>
          This Site or any portion of the Site may not be reproduced, duplicated,
          copied, sold, resold, visited, or otherwise exploited for any commercial
          purpose without our express written consent. You may not frame or utilize
          framing techniques to enclose any trademark, logo, or proprietary
          information (including images, text, page layout, or form) of HelloMD
          without our express written consent. You may not use any meta-tags or any
          other “hidden text” containing our trademarks or service marks without our
          express written consent. We (or the respective third-party owners of
          Content) retain all right, title, and interest in the Site and any Content
          and features offered on the Site, including any and all intellectual
          property rights.
        </p>
        <p>
          We (or the respective third-party owners of Content) reserve all rights not
          expressly granted herein. Any unauthorized use terminates the foregoing
          licenses and permissions.
        </p>
        <h2>Intellectual Property Rights</h2>
        <p>
          Except as otherwise expressly stated, all Content appearing on the Site is
          the copyrighted work of HelloMD or its third-party content providers and is
          protected by Canadian and international copyright laws. The compilation
          (meaning the selection, coordination, and arrangement) of the Site is also
          the exclusive property of HelloMD and is protected by Canadian and
          international copyright laws.
        </p>
        <p>
          Except as otherwise expressly stated herein, you may not alter, modify,
          copy, distribute (for compensation or otherwise), transmit, display,
          perform, reproduce, reuse, post, publish, license, frame, download, store
          for subsequent use, create derivative works from, transfer, or sell any
          information or Content obtained from the Site, in whole or in part,
          including any text, images, audio, and video in any manner, without the
          prior written authorization of HelloMD or any applicable third-party
          suppliers. The use of Content, including images, by you, or anyone else
          authorized by you, is prohibited unless specifically permitted by HelloMD.
          Any unauthorized use of text or images may violate copyright laws, trademark
          laws, the laws of privacy and publicity, and applicable regulations and
          statutes. HelloMD does not warrant or represent that your use of Content or
          any other materials displayed on the Site will not infringe rights of third
          parties. Your use of any of the Content beyond the scope of personal use may
          require a licence from the owner of the rights to the data with respect to
          the use of portrait right, trademark, copyright, design right, right of
          utilization or any other rights of the persons, products or landscape
          portrayed in the provided Content. HelloMD is not responsible for any claims
          of ownership rights to any images or data against you. You will indemnify,
          defend and hold harmless HelloMD from and against any losses or claims, by
          an owner of data or image rights or any third party resulting from any
          violation of these Terms of Use.
        </p>
        <p>
          Certain trademarks on the Site are the service marks and trademarks of
          HelloMD or its licensees. The domain name for the Site, the HelloMD logo and
          elements of the design of the Site are service marks, trademarks, logos,
          and/or trade dress of HelloMD. All other trademarks, service marks, trade
          dress, product names, company names or logos, whether registered or not, on
          the Site are the property of their respective owners. In addition to
          complying with all applicable laws, you agree that you will not use any such
          trademarks, service marks, trade dress, or other logos from the Site without
          the prior written authorization of HelloMD. Permission for all other uses of
          Content, service marks, trademarks, logos, trade dress or other intellectual
          property contained herein, including reproducing and distributing multiple
          copies or using Content, service marks, trademarks, logos, trade dress or
          other intellectual property on any other website or networked computer or
          linking to any page at the Site, must be obtained from HelloMD or the
          appropriate rights owner in advance; otherwise, such use is prohibited.
          Requests for such authorization from HelloMD should be submitted via email
          to support@hellomd.com. All design rights, databases and compilation and
          other intellectual property rights associated with the Site, in each case
          whether registered or unregistered, and related goodwill, are proprietary to
          HelloMD.
        </p>
        <p>
          Notice and Procedure for Making Claims of Intellectual Property Infringement
          HelloMD respects the intellectual property of others and takes the
          protection of copyrights and all other intellectual property very seriously,
          and HelloMD asks our users to do the same. HelloMD therefore employs
          measures to prevent copyright and other intellectual property infringement
          on the Site.
        </p>
        <h2>Ownership of Information Submitted via the Site</h2>
        <p>
          With the exception of any personal data or information you submit (which
          shall be maintained in accordance with our Privacy Policy), any information
          you transmit to HelloMD via the Site, whether by direct entry, submission,
          email or otherwise, including data, questions, comments, or suggestions,
          will be treated as non-confidential and non-proprietary and will become the
          property of HelloMD.
        </p>
        <p>
          Such information may be used for any purpose, including, without limitation,
          reproduction, solicitations, disclosure, transmission, publication,
          broadcast, and posting. HelloMD shall be free to use any ideas, concepts,
          know-how, or techniques contained in any communication you send to HelloMD
          via the Site or by any other means for any purpose whatsoever, including,
          without limitation, developing and marketing products using such
          information.
        </p>
        <h2>Connection Requirements</h2>
        <p>
          You are responsible for providing and maintaining, at your own risk, option
          and expense, any hardware, software and communication lines required to
          access and use the Site, and HelloMD reserves the right to change the access
          configuration of the Site at any time without prior notice. Prohibited Use
          Any use or attempted use of the Site (i) for any unlawful, unauthorized,
          fraudulent or malicious purpose, (ii) that could damage, disable,
          overburden, or impair any server, or the network(s) connected to any server,
          (iii) that could interfere with any other party’s use and enjoyment of the
          Site, (iv) to gain unauthorized access to any other accounts, computer
          systems, or networks connected to any server or systems through hacking,
          password mining or any other means, (v) to access systems, data, or
          information not intended by HelloMD to be made accessible to a user, (vi) to
          obtain any materials, or information through any means not intentionally
          made available by HelloMD, (vii) to reverse engineer, disassemble or
          decompile any section or technology on the Site, or (viii) any use other
          than the business purpose for which it was intended, is prohibited. In
          addition, in connection with your use of the Site, you agree you will not:
          (a) upload or transmit any message, information, data, text, software or
          images, or other content that is unlawful, harmful, threatening, abusive,
          harassing, tortuous, defamatory, vulgar, obscene, libelous, or otherwise
          objectionable, or that may invade another’s right of privacy or publicity;
          (b) create a false identity for the purpose of misleading others or
          impersonate any person or entity, including, without limitation, any HelloMD
          representative, or falsely state or otherwise misrepresent your affiliation
          with a person or entity; (c) upload or transmit any material that you do not
          have a right to reproduce, display or transmit under any law or under
          contractual or fiduciary relationships (such as nondisclosure agreements);
          (d) upload files that contain viruses, trojan horses, worms, time bombs,
          cancel-bots, corrupted files, spyware or any other similar software or
          programs that may damage the operation of another’s computer or property of
          another; (e) delete any author attributions, legal notices or proprietary
          designations or labels that you upload to any communication feature; (f) use
          the Site’s communication features in a manner that adversely affects the
          availability of its resources to other users (e.g., excessive shouting, use
          of all caps, or flooding continuous posting of repetitive text); (g) upload
          or transmit any unsolicited advertising including but not limited to
          advertising the personal sale, cultivation, manufacture, or distribution of
          cannabis for medical or commercial use by you or by or for other
          individuals, promotional materials, “junk mail,” “spam,” “chain letters,”
          “pyramid schemes,” “phishing” or any other form of solicitation, commercial
          or otherwise; (h) violate any applicable laws and regulations in your place
          of residence; (i) upload or transmit any material that infringes any patent,
          trademark, service mark, trade secret, copyright or other proprietary rights
          of any party; (j) delete or revise any material posted by any other person
          or entity; (k) manipulate or otherwise display the Site by using framing,
          mirroring or similar navigational technology; (l) probe, scan, test the
          vulnerability of or breach the authentication measures of, the Site or any
          related networks or systems; (m) register, subscribe, attempt to register,
          attempt to subscribe, unsubscribe, or attempt to unsubscribe, any party for
          any services or any contests, promotions or sweepstakes if you are not
          expressly authorized by such party to do so; (n) harvest or otherwise
          collect information about others, including email addresses; (o) use any
          robot, spider, scraper, or other automated or manual means to access the
          Site, or copy any content or information on the Site; or (p) assist or
          permit any person in engaging in any of these activities. HelloMD reserves
          the right to take whatever lawful actions it may deem appropriate in
          response to actual or suspected violations of the foregoing, including,
          without limitation, the suspension or termination of a user's access and/or
          account and assistance of law enforcement where necessary or when required.
          HelloMD may cooperate with legal authorities and/or third parties in the
          investigation of any suspected or alleged crime or civil wrong. Except as
          may be expressly limited by the Privacy Policy, HelloMD reserves the right
          at all times to disclose any information as HelloMD deems necessary to
          satisfy any applicable law, regulation, legal process or governmental
          request, or to edit, refuse to post or to remove any information or
          materials, in whole or in part, in HelloMD’s sole discretion.
        </p>
        <h2>Right to Monitor</h2>
        <p>
          HelloMD neither actively monitors general use of the Site under normal
          circumstances nor exercises editorial control over the content of any third
          party’s website, email transmission, news group, or other material created
          or accessible over or through the Site. However, HelloMD does reserve the
          right to monitor such use at any time as it deems appropriate and to remove
          any materials that, in HelloMD’s sole discretion, may be illegal, may
          subject HelloMD to liability, may violate these Terms of Use, or are, in the
          sole discretion of HelloMD, inconsistent with HelloMD’s purpose for the
          Site. No HelloMD Editorial Control of Third-Party Content; No Statement as
          to Accuracy To the extent that any of the Content included in the Site is
          provided by third-party content providers, Licensed Health Care Providers,
          or other Site users, HelloMD has no editorial control or responsibility over
          such Content. Therefore, any opinions, statements, products (with the
          exception of any cannabis or cannabis products in any form whatsoever which
          neither HelloMD nor its Licensed Health Care providers shall advertise on or
          provide through the Site or otherwise), services or other information
          expressed or made available by professionals, third-party suppliers or users
          on the Site are those of such professional, third-party suppliers or users,
          respectively. HelloMD does not represent or endorse the accuracy or
          reliability of any opinion, statement or other information provided by any
          third party, or represent or warrant that your use of the Content displayed
          on the Site or referenced content or service providers will not infringe
          rights of third parties not owned by or affiliated with HelloMD.
        </p>
        <h2>Links to Third-Party Sites</h2>
        <p>
          As you view the Site, you may see links to third-party websites. These links
          are for convenience only. If you use these links, you will leave the Site.
          Certain of these linked websites may make use of HelloMD’s proprietary
          intellectual property rights (such as copyrights, trademarks, service marks,
          logos and trade names) under license from the HelloMD. HelloMD is not
          responsible for the availability or content, products, services or other
          materials of these other websites or for any viruses or other damaging
          elements encountered in linking to a third-party website. In addition,
          providing links to these websites should not be interpreted as endorsement
          or approval by HelloMD of the organizations sponsoring such third-party
          websites or their products or services. HelloMD shall not be responsible or
          liable, directly or indirectly, for any damages or losses caused or alleged
          to be caused by or in connection with the use of or reliance on such
          content, products, services or other materials available on or through any
          such website. HelloMD is not responsible for the privacy practices of any
          other websites. These Terms of Use do not apply to any other websites.
        </p>
        <h2>Third-Party Interactions</h2>
        <p>
          Your interactions with entities or individuals found on or through the Site,
          including payment and delivery of goods or services, and any other terms,
          conditions, warranties or representations associated with such dealings, are
          solely between you and such entities or individuals. You should make
          whatever investigation you feel necessary or appropriate before proceeding
          with any online or offline transaction with any third party. You are solely
          responsible for, and will exercise caution, discretion, common sense and
          judgment in, using the Site and disclosing personal information. You agree
          that HelloMD shall not be responsible or liable for any loss or damage or
          resulting civil or criminal legal or administrative action(s) of any sort
          incurred as the result of any such dealings. If there is a dispute between
          participants on the Site, or between users and any third party, you
          understand and agree that HelloMD is under no obligation to become involved.
          In the event that you have a dispute with one or more other users, you
          hereby release HelloMD, its officers, employees, agents and successors in
          interest from claims, demand and damages (actual or consequential) of every
          kind or nature, known or unknown, suspected and unsuspected, disclosed or
          undisclosed, arising out of or in any way related to such disputes or the
          Site or the features and services therein.
        </p>
        <h2>Termination</h2>
        <p>
          HelloMD may terminate your use of the Site or any of our features or
          services at any time and for any reason without notice for conduct violating
          these Terms of Use. HelloMD will terminate a user’s access to the Site and
          its features and services if, under appropriate circumstances, the user is
          determined to be a repeat infringer of third-party rights or in breach of
          these Terms of Use. Upon any such termination, you must destroy all Content
          obtained from the Site and all copies thereof. The provisions of these Terms
          of Use concerning Site security, prohibited activities, copyrights,
          trademarks, user submissions, disclaimer, limitation of liability, indemnity
          and jurisdictional issues shall survive any such termination. You agree that
          if your use of the Site is terminated pursuant to these Terms of Use, you
          will not attempt to use the Site under any name, real or assumed, and
          further agree that if you violate this restriction after being terminated,
          you will indemnify and hold HelloMD harmless from any and all liability that
          HelloMD may incur therefore. Disclaimers Content and other information
          contained on the Site is provided by HelloMD as a convenience to its Users.
          Users relying on Content or other information from the Site do so at their
          own risk.
        </p>
        <p>
          THE HELLOMD SITE IS PROVIDED ON AN “AS IS” OR “AS AVAILABLE” BASIS. HELLOMD
          TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES AND
          CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
          NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, SATISFACTORY QUALITY AND FITNESS
          FOR PARTICULAR PURPOSE. HELLOMD MAKES NO WARRANTY THAT THE CONTENT IS
          ACCURATE, TIMELY, UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE, OR THAT ANY SUCH
          PROBLEMS WILL BE CORRECTED.
        </p>
        <h2>Limitation of Liability</h2>
        <p>
          IN NO EVENT SHALL HELLOMD BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT
          LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, PERSONAL INJURY/WRONGFUL
          DEATH, CRIMINAL CHARGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA
          OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OF OR INABILITY TO USE THE
          SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY,
          AND WHETHER OR NOT HELLOMD ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          HELLOMD SHALL BE LIABLE ONLY TO THE EXTENT OF ACTUAL DAMAGES INCURRED BY
          YOU, NOT TO EXCEED $1,000. NEITHER HELLOMD NOR ANY THIRD PARTIES MENTIONED
          ON THE SITE ARE LIABLE FOR ANY PERSONAL INJURY, INCLUDING DEATH, OR CRIMINAL
          ACTION AGAINST YOU CAUSED BY YOUR USE OR MISUSE OF THE SITE. ANY CLAIMS
          ARISING IN CONNECTION WITH YOUR USE OF THE SITE MUST BE BROUGHT WITHIN ONE
          (1) YEAR OF THE DATE OF THE EVENT GIVING RISE TO SUCH ACTION OCCURRED. YOU
          UNDERSTAND AND AGREE THAT YOUR USE OF THE SITE IS PREDICATED UPON YOUR
          WAIVER OF ANY RIGHT TO PARTICIPATE IN A CLASS ACTION SUIT FOR ANY LOSSES OR
          DAMAGES RESULTING FROM YOUR USE OF THE SITE.
        </p>
        <h2>Indemnification</h2>
        <p>
          You agree to defend, indemnify, and hold harmless HelloMD, its affiliates,
          contractors, and its directors, officers, employees, representatives,
          proprietors, partners, shareholders, servants, principals, agents,
          predecessors, successors, assigns, accountants, and attorneys from and
          against any and all suits, actions, claims, proceedings, damages,
          settlements, judgments, injuries, liabilities, obligations, losses, risks,
          costs, and expenses (including, without limitation, solicitors’ fees and
          litigation expenses) relating to or arising from the Site, your use of the
          Site, your fraud, violation of law, or wilful misconduct, and any breach by
          you of these Terms of Use.
        </p>
        <h2>Notices</h2>
        <p>
          Any notices to you from HelloMD regarding the Site or these Terms of Use
          will be posted on the Site or made by email or regular mail.
        </p>
        <h2>Electronic Communications</h2>
        <p>
          When you visit the Site or send emails to us, you are communicating with us
          electronically. You consent to receive communications from us
          electronically. We will communicate with you by email or by posting notices
          on the Site. You agree that all agreements, notices, disclosures and other
          communications that we provide to you electronically satisfy any legal
          requirement that such communications be in writing. You further agree that
          any notices provided by us electronically are deemed to be given and
          received on the date we transmit any such electronic communication as
          described in these Terms of Use.
        </p>
        <h2>Entire Agreement</h2>
        <p>
          These Terms of Use and other policies HelloMD may post on the Site or that
          you and HelloMD may execute constitute the entire agreement between HelloMD
          and you in connection with your use of the Site and supersedes any prior
          agreements between HelloMD and you regarding use of the Site, including
          prior versions of these Terms of Use.
        </p>
        <p>
          Governing Law; Venue; Severability of Provisions This Site is controlled and
          operated by HelloMD from our offices within Ontario, Canada. Those who
          choose to access the Site from other locations do so on their own initiative
          and are responsible for compliance with applicable laws. Access to the Site
          from jurisdictions where the Content and purpose of the Site is illegal or
          penalized is strictly prohibited. By choosing to visit the Site or otherwise
          provide information to HelloMD, you agree that any dispute over your use of
          the Site or these Terms of Use will be governed by the laws of the Province
          of Ontario, without regard to any conflicts of law provisions. You also
          consent to the adjudication of any disputes arising in connection with our
          Site in the courts of Ontario. Prior to the filing of any legal action
          resulting from these Terms of Use, you also agree to attempt to mediate any
          such dispute and to abide by all limitations of liability contained herein.
          All parts of these Terms of Use apply to the maximum extent permitted by
          law. We both agree that if we cannot enforce a part of this contract as
          written, that part will be replaced with terms that most closely match the
          intent of the part we cannot enforce to the extent permitted by law. The
          invalidity of part of these Terms of Use will not affect the validity and
          enforceability of the remaining provisions. The section headings are for
          convenience and do not have any force or effect.
        </p>
        <h2>No Agency Relationship</h2>
        <p>
          Neither these Terms of Use, nor any Content, materials or features of the
          Site create any partnership, joint venture, employment, or other agency
          relationship between us and you. You may not enter into any contract on our
          behalf or bind us in any way.
        </p>
        <h2>Remedies</h2>
        <p>
          You agree that any violation, or threatened violation, by you of these Terms
          of Use constitutes an unlawful and unfair business practice that will cause
          us irreparable and unquantifiable harm. You also agree that monetary damages
          would be inadequate for such harm and consent to our obtaining any
          injunctive or equitable relief that we deem necessary or appropriate. These
          remedies are in addition to any other remedies we may have at law or in
          equity.
        </p>
        <h2>Contacting Us</h2>
        <p>
          If you have any questions or concerns about these Terms of Use, please
          contact us at
          <a href="mailto:info@hellomd.com">info@hellomd.com</a>
          or at the mailing address provided. We will attempt to respond to your
          questions or concerns promptly after we receive them.
        </p>
      </Content>
    </MainLayout>
  )
}

export default MedicalTermsOfServicePage
